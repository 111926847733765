
@import './src/components/general.scss';
@import './src/components/mixins.scss';


#navbarrrr {
    background-color: $everything-beige2;
    color: $dark-logo-color;
  }

.img-fluid {
    position: relative; /* Necessary for absolute positioning of the overlay */
    overflow: hidden; /* Prevents overlay from spilling out of the image */
    transition: transform 0.3s ease; /* Smooth scaling transition */
    border-radius: 8px;
    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Overlay effect on hover */
  .img-fluid::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    opacity: 200;
    transition: opacity 0.3s ease; /* Smooth opacity transition */
  }
  
  /* Apply scaling effect on hover */
  .img-fluid:hover {
    transform: scale(1.03); /* Slightly larger image on hover */
  }
  
  /* Show overlay on hover */
  .img-fluid:hover::after {
    opacity: 7; /* Make overlay visible */
  }
  
 #gallery{
    background-color: $everything-beige2;
    color: $dark-logo-color;
  }



  .img-container {
    position: relative;
    overflow: hidden;
    padding-top: 75%; /* Aspect ratio 4:3 */
  }

  #Services2{
    @include darkgreenbackground
  }
  
 