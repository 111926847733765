@import './src/components/general.scss';
@import './src/components/mixins.scss';

#contactinfocard, #formcard, #gettintherecard{
    @include lightgreenbackground;
}

@media (max-width: 800px) {
    #formcarddiv {
      margin-bottom: 20px; // Add margin-bottom for small screens
      
    }}




#contactsection {
    .container {
      padding-top: 2rem;
      padding-bottom: 2rem;
      border: none;
  
      .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        border: none;
  
        .col-md-6 {
          flex: 0 0 50%;
          max-width: 50%;
          padding-right: 15px;
          padding-left: 15px;
          margin-bottom: 20px;
          border: none;
  
          @media (max-width: 600px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  
  #contactinfocard, #formcarddiv, #gettintherecard {
    background: $mid-logo-color;
    border-radius: 3rem;
    padding: 2rem;
    margin-bottom: 3rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
  
    h2, h1 {
      margin-bottom: 1.5rem;
      font-weight: bold;
    }
  
    p {
      font-size: 1.1rem;
    }
  
    button {
      margin-top: 1rem;
    }
  }
  
  .map-container {
    .map-frame {
      border-radius: 1rem;
      width: 100%;
      height: 400px;
      border: none;
    }
  }
  
  #formcarddiv,
  #formcard,
  .card {
    border: none ; /* Use !important as a last resort */
  }

  // ContactPage.scss

/* Custom styles for the modal */
.modal-content {
  border-radius: 0.5rem; // Adjust border radius
  background-color: #f8f9fa; // Custom background color
  border: 1px solid #dee2e6; // Custom border color
  justify-content: center;
}

.modal-header {

  color: #495057; // Header text color
  justify-content: center;
}

.modal-title {
  font-size: 1.5rem; // Adjust title font size
  justify-content: center;
}

.modal-body {
  font-size: 1.2rem; // Adjust body font size
  color: #495057; // Body text color
  justify-content: center;
}

.modal-footer {
  border-top: 1px solid #dee2e6; // Custom footer border color
}

.btn-secondary {
  background-color: $hotpink; // Button background color
  border: none; // Remove button border
  color: #fff; // Button text color
}

.btn-secondary:hover {
  background-color: $lightpink // Button hover color
}
