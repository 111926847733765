@import './src/components/general.scss';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@font-face {
    font-family: 'Higuen Elegant Serif';
    src: url('../fonts/Higuen Serif.otf') format('opentype'); /* Preferred format */
  }
  @import './src/components/mixins.scss';
  
  * {
    box-sizing: border-box; // Ensure padding and border are included in element's total width and height
}

body {
    background-color: $background-color;
    background-size: cover;
    font-family: 'Higuen Elegant Serif', serif;
    color: $everything-beige;
    overflow-x: hidden; // Prevent horizontal scrolling
    margin: 0;
    padding: 0;
    
  }

  html, body {
    overflow-x:hidden ;
    
  } 
  
  #navbar {
    background-color: $background-color;
    color: $everything-beige;
    margin-left: auto;
 
 }

 .navbar-collapse{justify-content: center;}

  
  .nav-link {
    color: inherit; // Inherit the color from parent
    text-decoration: none; // Remove the underline
    padding-left: 23px;
  }

  .nav-link:hover {
    color: $everything-beige; // Inherit the color from parent
    
  }

.navbar-toggler-icon{
    color: $black;
}
  
 

 #newbtn{
    height: 50px;
    width: 120px;
    cursor: pointer;
    border: none;
    border: 2px solid $hotpink;
    color: $everything-beige;
    background-color: $hotpink;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    font-size: 17px;
  
    &::before {
      content: "";
      position: absolute;
      height: 0%;
      width: 100%;
      background: none;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.5s;
    }
  
    &:hover {
      color: $everything-beige;
      z-index: 999;
      border: 2px solid $everything-beige;
      background: none;
  
      &::before {
        height: 100%;
        
      }
    }
 }
  
  
 
  
  #card {
    background-color: $dark-logo-color;
  }
  
 

#footer{
    @include pinkbackground
}

#foot {
    display: flex;
    justify-content: space-between;
   
    text-align: left;
  }
  .footer-text {
    margin-top: 20px; /* Adjust as needed */
  }




#homegallery{
    @include lightbackground;

   
      
    
}


  
#navbarr {
    
    color: $everything-beige;
  }

  #newbtnHome{
    height: 50px;
    width: 120px;
    cursor: pointer;
    border: none;
    border: 2px solid $hotpink;
    color: $dark-logo-color;
    background-color: $hotpink;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    font-size: 17px;
  
    &::before {
      content: "";
      position: absolute;
      height: 0%;
      width: 100%;
      background: none;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.5s;
    }
  
    &:hover {
      color: $everything-beige;
      z-index: 999;
      border: 2px solid $lightpink;
      background: $lightpink;
  
      &::before {
        height: 100%;
        
      }
    }
 };

 #langbtn{
  height: 42px;
  width: 67px;
  cursor: pointer;
  border: none;
  border: none;
  color: $everything-beige;
  background-color: rgba(235, 218, 218, 0.5);
  
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  font-size: 17px;
  margin-right: 17px;
  margin-left: 17px;

  &::before {
    content: "";
    position: absolute;
    height: 0%;
    width: 100%;
    background: none;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.5s;
  }

  &:hover {
    color: $everything-beige;
    background: $hotpink;
    z-index: 999;
    border: 2px solid $hotpink;
    

    &::before {
      height: 100%;
      
    }
  }
}

.logo2 {
  display: none; /* Hide the second logo by default */
}

@media (max-width: 768px) {
  .logo {
    display: none; /* Hide the first logo on small screens */
  }

  .logo2 {
    display: block; /* Show the second logo on small screens */
    margin-top: 29px;
  }
}

