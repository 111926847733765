$logo-color: #40ffb5;
$background-color: #032b22;
$lighter-background-color: lighten($background-color, 4%);
$dark-logo-color: #2a281d;
$mid-logo-color: #00634d;
$light-logo-color: #819d9f;
$black: #111111;
$lighter-black: #2a2a2a;
$everything-beige: #efede7;
$everything-beige2: #d2cdb1;
$hotpink: #ffafda;
$lightpink: #f9d5e8;
$darktext: #9aa195;
