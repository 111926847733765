@import './src/components/general.scss';
@import './src/components/mixins.scss';

#navbarr {
    background: none;
    color: $everything-beige; // Ensure this variable is defined
}

#homehero {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden; // Ensure that the blurred background doesn't overflow
    overflow-x: hidden;

    // Use a pseudo-element to apply the blurred background
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../pictures/kislampa.jpeg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        filter: blur(2.5px); // Adjust the blur amount here
        z-index: 1;
    }

    // Ensure that the content is above the blurred background
    > * {
        position: relative;
        z-index: 2;
    }

    h1 {
        color: $mid-logo-color; // Ensure this variable is defined
        font-size: 4.5rem;

        @media (min-width: 600px) {
            font-size: 5rem;
        }

        @media (min-width: 750px) {
            font-size: 5.8rem;
        }

        @media (min-width: 900px) {
            font-size: 6.1rem;
        }

        @media (min-width: 1000px) {
            font-size: 6.8rem;
        }
    }

    p {
        font-size: 1.2rem;

        @media (min-width: 700px) {
            font-size: 1.5rem;
        }
    }

    
}

#text {
    padding-top: 20px;
    padding-right: 30px;

    @media (min-width: 700px) {
        padding-top: 30px;   /* Proper unit for padding-top */
             /* Proper unit for margin-top */
    }

    @media (min-width: 1000px) {
        padding-top: 73px;   /* Proper unit for padding-top */
             /* Proper unit for margin-top */
    }
}




#headertry {
    @include custom-shape(polygon(30% 0%, 600% 30%, 30% 800%, 0% 30%)); // Ensure this mixin is defined
    background-color: $background-color; // Ensure this variable is defined
    color: $logo-color; // Ensure this variable is defined

    #hero {
        max-width: 400px;
        color: $logo-color; // Ensure this variable is defined

        h1 {
            color: $logo-color; // Ensure this variable is defined
        }

        p {
            color: $logo-color; // Ensure this variable is defined
        }
    }
}

/* Other sections */
